<script setup lang="ts">
import type { MediaListRowData } from '~/types/mediaList'
import { ref, onMounted, computed } from 'vue'
import IconFeatured from '~/components/icons/IconFeatured.vue'
import IconSponsored from '~/components/icons/IconSponsored.vue'

const { title, route, items, type, pending } = defineProps<MediaListRowData>()

const scrollContainer = ref<HTMLElement | null>(null)
const showLeftButton = ref(false)
const showRightButton = ref(false)
const itemWidth = ref(0)
const containerWidth = ref(0)

// Compute how many items to scroll based on container width and item width
const scrollItemCount = computed(() => {
  if (!containerWidth.value || !itemWidth.value) return 1
  const visibleItems = Math.floor(containerWidth.value / itemWidth.value)

  // Return visible items count, but cap it between 1 and 5
  return Math.max(1, Math.min(5, visibleItems))
})

onMounted(() => {
  if (!scrollContainer.value) return

  const updateDimensions = () => {
    if (!scrollContainer.value) return

    // Get container width
    containerWidth.value = scrollContainer.value.clientWidth

    // Get first item width
    const firstItem = scrollContainer.value.querySelector('.item-container')
    if (firstItem) {
      itemWidth.value = firstItem.getBoundingClientRect().width
    }

    updateScrollButtons()
  }

  // Initial update
  updateDimensions()

  // Set up resize observer to handle container size changes
  const resizeObserver = new ResizeObserver(updateDimensions)
  resizeObserver.observe(scrollContainer.value)

  scrollContainer.value.addEventListener('scroll', updateScrollButtons)

  // Cleanup
  onUnmounted(() => {
    resizeObserver.disconnect()
    scrollContainer.value?.removeEventListener('scroll', updateScrollButtons)
  })
})

const updateScrollButtons = () => {
  if (!scrollContainer.value) return
  const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value
  showLeftButton.value = scrollLeft > 0
  showRightButton.value = scrollLeft < scrollWidth - clientWidth - 1
}

const scroll = (direction: 'left' | 'right') => {
  if (!scrollContainer.value) return
  const scrollAmount = itemWidth.value * scrollItemCount.value
  const currentScroll = scrollContainer.value.scrollLeft

  scrollContainer.value.scrollTo({
    left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
    behavior: 'smooth',
  })
}
</script>

<template>
  <section class="relative">
    <div class="flex flex-row justify-between items-center mb-4">
      <div class="sm:text-lg">
        <NuxtLink v-if="route" :to="route" class="hover:underline">
          <h2>{{ title }}</h2>
        </NuxtLink>
        <h2 v-else>{{ title }}</h2>
      </div>
      <div class="flex items-center space-x-3">
        <TagLink v-if="route" :route="route">More</TagLink>

        <button
          :disabled="!showLeftButton"
          class="scroll-button"
          :class="{ 'button-disabled': !showLeftButton }"
          aria-label="slide left"
          @click="scroll('left')"
        >
          <Icon size="18" name="solar:alt-arrow-left-linear" :class="{ 'text-gray-300': !showLeftButton }" />
        </button>

        <button
          :disabled="!showRightButton"
          class="scroll-button"
          :class="{ 'button-disabled': !showRightButton }"
          aria-label="slide right"
          @click="scroll('right')"
        >
          <Icon size="18" name="solar:alt-arrow-right-linear" :class="{ 'text-gray-300': !showRightButton }" />
        </button>
      </div>
    </div>

    <div v-if="!pending && items && items.length === 0" class="text-center p-16 text-lg">
      No results were found that match your criteria.
    </div>

    <div v-else>
      <div ref="scrollContainer" class="overflow-x-scroll hidden-scroll scroll-smooth">
        <div class="flex flex-row max-w-screen-lg">
          <template v-if="items && items.length">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="item-container px-0.5 shrink-0 grow-0 w-1/5 min-w-36 relative"
            >
              <span
                v-if="item?.sponsored"
                title="Sponsored"
                class="absolute top-3 left-3 p-1 bg-zeno-yellow rounded-full flex items-center gap-1.5 z-10"
              >
                <IconSponsored />
                <span class="text-xs text-white pr-2.5">Sponsored</span>
              </span>

              <span v-else-if="item?.featured" class="absolute top-3 left-3" title="Featured">
                <IconFeatured />
              </span>

              <div class="h-full bg-zeno-gray-light rounded-xl py-6 flex flex-col space-y-4 lg:px-6 sm:px-4 px-2">
                <NuxtLink :to="item.route" class="text-center flex">
                  <MediaListRowItemLogo
                    :src="item.logo"
                    :alt="item.title"
                    :variant="type === 'stations' ? 'circle' : 'square'"
                  />
                </NuxtLink>
                <NuxtLink :to="item.route" class="line-clamp-2 hyphens-auto sm:text-base text-sm">
                  {{ item.title }}
                </NuxtLink>
              </div>
            </div>
          </template>

          <template v-else>
            <div
              v-for="(_, index) in 5"
              :key="index"
              class="item-container px-0.5 shrink-0 grow-0 inline-block w-1/5 min-w-36 animate-pulse"
            >
              <div class="h-full bg-zeno-gray-light rounded-xl py-6 flex flex-col space-y-4 lg:px-6 sm:px-4 px-2">
                <span
                  class="aspect-square w-full min-w-28 min-h-28 drop-shadow-[0_6px_10px_rgba(0,0,0,0.04)] bg-white border border-zeno-gray-border-light inline-block"
                  :class="type === 'stations' ? 'rounded-full' : 'rounded-xl'"
                />
                <div class="h-3 mb-2 bg-white rounded" />
                <div class="h-3 bg-white rounded" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.scroll-button {
  @apply size-9 flex justify-center items-center border border-zeno-gray-border-light rounded-full
  transition-all duration-200 hover:bg-gray-50 active:bg-gray-100;
}

.button-disabled {
  @apply hover:bg-transparent active:bg-transparent;
}
</style>
